<template>
  <div>
    <h1 class="title">Books sorted by old catalog #</h1>
    <table class="table" v-if="done">
      <thead>
        <tr>
          <th>id</th>
          <th>Old #</th>
          <th>Dewey</th>
          <th>Title</th>
          <th>Author</th>
          <th>Metadata</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(book, i) in books" v-bind:key="book.id">
          <td>{{book.id}}</td>
          <td :style="'background-color: ' + sections[book.attributes.section_id].attributes.colour">
            {{ sections[book.attributes.section_id].attributes.name }}<br />
              {{ book.attributes.catno }}
          </td>
          <td>{{book.attributes.call_number }}</td>
          <td>{{book.attributes.title }}</td>
          <td>{{book.attributes.author }}</td>
          <td>
            <span v-if="book.attributes.author_is_editor" class="tag small is-warning">Editor</span>
            <span v-if="book.attributes.author_is_institution" class="tag small is-secondary">Institution</span>
          </td>
          <td> 
            <button :disabled="updating" class="button is-small is-secondary" @click="toggleInstitution(i)">Toggle institution</button>
            <button :disabled="updating" class="button is-small is-warning"  @click="toggleEdited(i)">Toggle edited</button>
            <button :disabled="updating" class="button is-small is-secondary" @click="cleanPartnership(i)">Clean patnership</button>
       
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <img src="@/assets/ajax-loader.gif"/>
    </div>
  </div>

</template>

<script>
import normalize from 'json-api-normalizer'
export default {
  data () {
    return {
      updating: false,
      done: false,
      fetching: true,
      paginationMeta: null,
      totalPages: null,
      books: null,
      sections: [],
      restrictSection: null
    }
  },
  methods : {

  },
  mounted () {
    this.axios.get('/v1/sections')
      .then((response) => {
        this.sections = normalize(response.data).section
      })
    this.axios.get("/v1/housekeeping/old_order")
      .then((response) => {
        this.books = response.data.data
        this.done = true
      })
  }
}
</script>
